import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";


const OfferSubscribeModal = ({ offerSubData, close, applyOffer, loadingBtn }) => {
  const userData = useSelector((state) => state?.Reducer?.loginData);
  const { conversion_rate } = userData;
  const appName = sessionStorage?.getItem('appName');
  const [rateCon, setRateCon] = useState("");
  const [operatorNetwork, setOperatorNetwork] = useState("");
  
  const consentToken = appName === 'BangTopup' ? Cookies.get("consentBangTopupToken") : Cookies.get("consentRichtechTopupToken");
  
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode] = useState("+88");
  const [accountNum, setAccountNum] = useState("");
  

   useEffect(() => {
    if(appName === 'BangTopup') {
     if(offerSubData.amount) {
      const rateConvert = offerSubData.amount / conversion_rate;
      setRateCon(rateConvert);
     }

     if(offerSubData?.sub_service_name){
       switch(offerSubData?.sub_service_name){
         case 'Banglalink':
          setOperatorNetwork('BL');
          break;
         case 'GrameenPhene': 
         setOperatorNetwork('GP');
         break;
         case 'Robi': 
         setOperatorNetwork('RB');
         break; 
         case 'Airtel': 
         setOperatorNetwork('AT');
         break;  
         case 'TeleTalk': 
         setOperatorNetwork('TT');
         break;
         case 'Skitto': 
         setOperatorNetwork('GP ST');
         break;
         default: setOperatorNetwork('');
       }
     }
    } 
   },[offerSubData.amount, conversion_rate, offerSubData?.sub_service_name, appName, operatorNetwork]);

 
  const subscribePkg =  () => {
      if(appName === 'BangTopup') {
        if(phoneNumber === "") {
          toast.error('Please, Fill Out Phone Number', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        } else if(phoneNumber.length < 10) {
          toast.error("Please enter valid mobile number");
        } else {
        let body = {
          msisdn: phoneCode + "" + phoneNumber,
          amount: Number(rateCon),
          operator: operatorNetwork,
          con_type: offerSubData?.connection_type,
          recharge_type: "Offer",
          offer_remarks: offerSubData.remarks,
          consent_token: consentToken
        }
        applyOffer(body);
      }
      } else {
        if(phoneNumber === "") {
          toast.error("Please, Fill Out Phone Number", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        } else if(phoneNumber.length < 10) {
          toast.error("Please enter valid mobile number");
        } else if(accountNum === "") {
          toast.error("Please, Fill Out Account Number", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        } else if(accountNum.length < 10) {
          toast.error("Please enter valid account number");
        } else {
      let body = {
        mobileNumber: phoneNumber,
        accountNumber: accountNum,
        productId: offerSubData?.ID,
        price: Number(offerSubData?.Cost),
        consent_token: consentToken
      }
      applyOffer(body);
    }
    }
  };

  const handlePhoneNumber = (e) => {
    const { value } = e.target;
    const regex = /^[0-9]*$/;
    if(regex.test(value) || value === "") {
      setPhoneNumber(value);
    }
  }

  const handleAccountNumber = (e) => {
    const { value } = e.target;
    const regex = /^[0-9]*$/;
    if(regex.test(value) || value === "") {
      setAccountNum(value);
    }
  }

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center overflow-hidden rounded-md">
      <div className="fixed inset-0 z-10 overflow-hidden bg-black opacity-50"></div>
      <div className="terms-modal fixed top-0 left-0 z-20 flex h-full w-full items-center justify-center">
        <div className="relative max-h-[90vh] max-w-[90vw] rounded-lg bg-white shadow-md p-6 sm:max-w-[80vw]">
          <h1 className="font-poppins text-lg text-center font-semibold text-primary-black pb-3">
            {"Enter Number To Subscribe Package"}
          </h1>
          { appName === 'BangTopup' ? (
               <div className="space-y-1 mb-6">
               <label className="font-mulish text-sm font-medium text-secondary-grey" htmlFor="phoneNumber">
                 Phone Number
               </label>
               <input
                 type="tel"
                 placeholder="Enter phone number"
                 name="phoneNumber"
                 value={phoneNumber}
                 onChange={handlePhoneNumber}
                 className={`w-full outline-none border-[1px] pl-2 text-secondary-grey border-secondary-grey h-12 rounded-md`}
                 maxLength={11}
                 minLength={10}
               />
             </div>
          ) : (
           <>
              <div className="space-y-1 mb-6">
            <label className="font-mulish text-sm font-medium text-secondary-grey" htmlFor="phoneNumber">
              Phone Number
            </label>
            <input
              type="tel"
              placeholder="Enter phone number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handlePhoneNumber}
              className={`w-full outline-none border-[1px] pl-2 text-secondary-grey border-secondary-grey h-12 rounded-md`}
              maxLength={11}
              minLength={10}
            />
          </div>
          <div className="space-y-1 mb-6">
            <label className="font-mulish text-sm font-medium text-secondary-grey" htmlFor="phoneNumber">
              Account Number
            </label>
            <input
              type="text"
              placeholder="Enter account number"
              name="accountNum"
              value={accountNum}
              onChange={handleAccountNumber}
              className={`w-full outline-none border-[1px] pl-2 text-secondary-grey border-secondary-grey h-12 rounded-md`}
              maxLength={11}
              inputMode="numeric"
            />
          </div>
           </>
          ) }
         
          <div className="flex items-center gap-3">
            <button
              onClick={close}
              className="mb-3 flex justify-center items-center bg-primary-red w-full  h-10 rounded-lg button text-white"
            >
              Close
            </button>
            <button
              onClick={subscribePkg}
              className="mb-3 flex justify-center items-center bg-primary-red w-full  h-10 rounded-lg button text-white"
            >
              { loadingBtn ? (
                             <TailSpin
                             height="20"
                             width="20"
                             color="#ffffff"
                             ariaLabel="tail-spin-loading"
                             radius="1"
                             wrapperStyle={{}}
                             wrapperClass=""
                             visible={true}
                           />
                           ) : (
                            <>Subscribe</>
                           )}
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default OfferSubscribeModal;
