import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
// Import other reducers if you have any
import Reducer from "./context/reducers/reducer";

const rootReducer = combineReducers({
  Reducer
});

export const store = configureStore({
  reducer: rootReducer,
});
