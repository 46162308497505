import React, { useEffect, useState } from "react";
import { Package } from "../components";
import { axiosInstance } from "../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import Product from "../components/Product";
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';

const Offers = () => {
  const userData = useSelector((state) => state?.Reducer?.loginData);
  const { accessToken } = userData;
  const appName = sessionStorage?.getItem('appName');


  // const userData = useSelector((state) => state?.Reducer?.loginData);
  // const appName = useSelector((state) => state?.Reducer?.appName);
  // const {  accessToken } = userData;
  const [network, setNetwork] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    if(network){
      showOffers();     
    } 
  }, [network]);
  
  useEffect(() => {
    if(appName === 'RichtechTopup'){
      showRichTechOffers();
    }
  },[]);
  
  const handleNetworkChange = (e) => {
   setNetwork(e.target.value);    
  }

  const showRichTechOffers = async () => {
    setLoading(true);
    const url = `/api/RichtechTopup/getProductsList`;
    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      const { status } = response.data;
      if(status === 200) {
        const { data } = response.data;
        setProductData(data);
        setLoading(false);
    }
  }catch(error) {
    setLoading(false);
    toast.error(error?.response?.data?.data?.message);
  }
}

  const showOffers = async () => {
    setLoading(true);
   
     let url = `/api/BangTopup/mobileRechargeTopupOffers/${network}`; 
   
    try {
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      const { status } = response.data;
      if(status === 200) {
        const { data } = response.data;
       
          setPackageData(data);
        
        setLoading(false);
      }
     
    } catch (error) {
      toast.error(error?.response?.data?.data?.message);
    }
  }
 
  return (
    <section className="container mt-6">
      <div className="lg:mx-auto lg:w-[500px]">
        {  appName === 'BangTopup' && (
          <>
        <h1 className="heading-one !text-black mb-4">Networks</h1>
        <div className="flex flex-col items-start justify-start gap-4">
        <div className={`mb-4 w-full`}>
            <label
              htmlFor="operator"
              className="font-mulish text-sm font-medium text-secondary-grey"
            >
              Operator
            </label>
            <select
              id="operator"
              name="operator"
              value={network}
              onChange={handleNetworkChange}
              className={`w-full outline-none border-[1px] pl-2 text-secondary-grey border-secondary-grey h-12 rounded-md`}
              // required
            >
              <option value="">Choose an operator</option>
              <option value="GP">GrameenPhene - GP</option>
              <option value="BL">Banglalink - BL</option>
              <option value="RB">Robi - RB</option>
              <option value="AT">Airtel - AT</option>
              <option value="TT">TeleTalk - TT</option>
              <option value="GP ST">Skitto - GP ST</option>
              {/* Add other options if needed */}
            </select>
          </div>
        </div>
        </>
        )}
        
      <div>
      <div className="">
        <div className={`space-y-4 ${loading ? 'flex justify-center': ''}`}>
          { loading ? (
           <div className="">
             <TailSpin
              height="40"
              width="40"
              color="#000000"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
           </div>
          ) : 
          <div className="space-y-4">
          { appName === 'BangTopup' ? (
            <>
            { packageData?.map((pkgData, index) => {
              return <Package key={index} pkgData={pkgData} />
            }) }
            </>
          ) : (
            <>
            { 
              productData?.map((proData, index) => {
                return <Product proData={proData} key={index} />
              })
            }
            </> 
          )}   
        </div>
          }
        </div>
      </div>
      </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Offers;
