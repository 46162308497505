import React, { useState } from "react";
import OfferSubscribeModal from "./OfferSubscribeModal";
import { axiosInstance } from "../utils"; 
import RechargeMsgModal from "./RechargeMsgModal"; 
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";


const Package = ({pkgData}) => {
const userData = useSelector((state) => state?.Reducer?.loginData);
const { accessToken } = userData;

  const [openModal, setOpenModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [offerResponse, setOfferResponse] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const openSubscribeModal = () => {
    setOpenModal(true);
    document.body.style.overflow = "hidden";
  };

  const onClose = () => {
    setOpenModal(false);
    document.body.style.overflow = "auto";
  };

  const responseClose = () => {
    setResponseModal(false);
    document.body.style.overflow = "auto";
  }

  const applyOffer = async (body) => {
    try{
      setLoadingBtn(true);
     const response = await axiosInstance.post("/api/BangTopup/mobileRechargeTopup", body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
     })
     const { status } = response.data;
     if(status === 200) {
      setLoadingBtn(false);
      const { data } = response.data;
      toast.info(data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
          if(data?.redirect_url) {
            window.location.href = data?.redirect_url;
          }
      onClose();
      // setResponseModal(true);
      // setOfferResponse(data);
     }
    }catch(error){
      toast.error(error?.response?.data?.data?.message);
      onClose();
      setLoadingBtn(false);
    }
  }

  return (
    <div className="bg-white shadow-xl p-4 flex flex-col gap-5 rounded-md">
       <h4 className="font-medium">{pkgData?.amount} BDT</h4>
       <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col gap-1">
          <h3 className="font-mulish font-semibold text-sm text-black">Offer Type</h3>
          <p className="text-sm font-mulish font-medium">{pkgData?.type}</p>
        </div>
        <div className="flex flex-col gap-1 col-span-2">
          <h3 className="font-mulish font-semibold text-sm text-black">Connection Type</h3>
          <p className="text-sm font-mulish font-medium">{pkgData?.connection_type}</p>
        </div>
        <div className="flex flex-col gap-1 col-span-4">
          <h3 className="font-mulish font-semibold text-sm text-black">Remarks</h3>
          <p className="text-sm font-mulish font-medium">{pkgData?.remarks}</p>
        </div>
       </div>
       <div className="flex  justify-end">
       <button className="text-white bg-secondary-grey p-1 rounded-3xl flex justify-center items-center w-28" onClick={openSubscribeModal}>Subscribe</button>
       </div>
     {openModal && <OfferSubscribeModal close={onClose} offerSubData={pkgData} applyOffer={applyOffer} loadingBtn={loadingBtn} />}
     {responseModal && (
        <RechargeMsgModal recharge={offerResponse} close={responseClose} />
      )}
      <ToastContainer />
    </div>
  );
};

export default Package;
