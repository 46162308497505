import React from 'react'

const HistoryCard = ({data}) => {
  return (
    <div className="w-full  p-4 rounded-md shadow-xl">
        <div className='flex flex-col gap-16 '>
        <div className='grid grid-cols-2 gap-4'>
        
            <div className='flex flex-col gap-1 items-start'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>Transaction ID</h6>
                <h6 className='text-base  font-mulish font-medium text-primary-lightWhite'>{data?.trx_id}</h6>
            </div>
            <div className='flex flex-col gap-1 items-end'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>Amount</h6>
                <h6 className='text-base font-mulish font-medium text-primary-lightWhite'>{data?.amount ?? data?.price} RM</h6>
            </div>
            <div className='flex flex-col gap-1 items-start'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>Status</h6>
                <h6 className={`text-base font-mulish font-medium ${data?.status === 'failed' ? 'text-red-700' : 'text-[#27BE0E]'} `}>{data?.status}</h6>
            </div>
            <div className='flex flex-col gap-1 items-end'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>Mobile No:</h6>
                <h6 className={`text-base font-mulish font-medium  `}>{data?.customer_mobile_no}</h6>
            </div>
            <div className='flex flex-col gap-1 items-start col-span-2'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>Time</h6>
                <h6 className='text-base font-mulish font-medium text-primary-lightWhite'>{data?.created_at}</h6>
            </div>
        </div>
        </div>
       
    </div>
  )
}

export default HistoryCard