import React from 'react'
import ReactDOM from "react-dom";

const EnquiryMsgModal = ({rechargeData, close}) => {
  const appName = sessionStorage?.getItem('appName');

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center overflow-hidden rounded-md">
    <div className="fixed inset-0 z-10 overflow-hidden bg-black opacity-50"></div>
    <div className="terms-modal fixed top-0 left-0 z-20 flex h-full w-full items-center justify-center">
      <div className="relative max-h-[90vh] max-w-[90vw] rounded-lg bg-white shadow-md p-6 sm:max-w-[80vw]">
      { appName === 'BangTopup' ? (
        <>
        <h1 className='font-poppins text-lg text-center font-semibold text-primary-black pb-3'>{rechargeData?.status_name}</h1>
       <div className='flex flex-col gap-3 pb-8'>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Number:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.recipient_msisdn}</p>
        </div>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Amount:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.amount} BDT</p>
        </div>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Con Type:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.connection_type}</p>
        </div>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Operator:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.operator_name}</p>
        </div>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Client Tranx Id:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.tran_id}</p>
        </div>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Request Date:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.request_date}</p>
        </div>
        </div>
        </>
      ) : (
        <>
          <h1 className='font-poppins text-lg text-center font-semibold text-primary-black pb-3'>{rechargeData?.TransactionStatus}</h1>
          <div className='flex flex-col gap-3 pb-8'>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Response Message:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>Payment status will update after 30sec</p>
        </div>
        {/* <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Response Status:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.ResponseStatus} </p>
        </div> */}
        {/* <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Con Type:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.connection_type}</p>
        </div>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Operator:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.operator_name}</p>
        </div>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Client Tranx Id:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.tran_id}</p>
        </div>
        <div className='flex justify-between items-center gap-10'>
            <p className='text-base font-medium font-poppins text-primary-black'>Request Date:</p>
            <p className='text-base font-medium font-poppins text-primary-black'>{rechargeData?.request_date}</p>
        </div> */}
       </div>   
          
        </>
      ) }
       <button onClick={close} className='mb-3 flex justify-center items-center bg-primary-red w-full  h-10 rounded-lg button text-white'>Close</button>
      </div>
    </div>
  </div>,
  document.body
  )
}

export default EnquiryMsgModal