import React, { useEffect, useState } from 'react'
import Card from "../components/Card";
import HistoryCard from "../components/HistoryCard";
import { axiosInstance } from '../utils'; 
import { ToastContainer, toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";

const History = () => {
  const userData = useSelector((state) => state?.Reducer?.loginData);
  const { accessToken } = userData;
  const appName = sessionStorage?.getItem('appName');
  const [historyList, setHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);

   useEffect(() => {
      getHistory();
   }, []);

  const getHistory = async () => {
    setLoading(true);
    try{
      let url;
      if(appName === 'BangTopup') {
        url = `/api/BangTopup/getRechargeHistory`; 
      } else {
        url = `/api/RichtechTopup/getRechargeHistory`;
      }
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      const { status } = response.data;
      if(status === 200) {
        setLoading(false);
        const { data } = response.data;
        setHistoryList(data);
      }
    }catch(error){
      toast.error(error?.response?.data?.data?.message);
      setLoading(false);
    }
  }

   

  return (
    <section className="container mt-6 space-y-8">
      <h1 className='text-2xl'>History</h1>
      { loading ? (
        <div className="flex justify-center">
        <TailSpin
         height="40"
         width="40"
         color="#000000"
         ariaLabel="tail-spin-loading"
         radius="1"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
       />
      </div>
      ) : (
        historyList.length === 0 ? (
          <p className='text-center font-mono text-lg'>No history found</p>
        ) : (
          <>
          { appName === 'BangTopup' ? (
            <>
            { historyList?.map((data, index) => { return ( <Card key={index} data={data} />) }) }
            </>

          ) : (
            <>
            { historyList?.map((data, index) => { return ( <HistoryCard key={index} data={data} />) }) }
            </>
          ) }
          </>
        )
      ) }
      <ToastContainer />
    </section>
  )
}

export default History