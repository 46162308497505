import React, { useState, useEffect } from "react";
import { Button, RechargeMsgModal } from "../components";
import {
  axiosInstance
} from "../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";


const Recharge = () => {
  const userData = useSelector((state) => state?.Reducer?.loginData);
  const { accessToken, conversion_rate } = userData;
  const [amount, setAmount] = useState("");
  const [operator, setOperator] = useState("");
  const [planType, setPlanType] = useState("prepaid");
  const [tel, setTel] = useState("");
  const [phoneCode, setPhoneCode] = useState("+88");
  const [errorAmount, setErrorAmount] = useState(false);
  const [errorOperator, setErrorOperator] = useState(false);
  const [errortel, setErrorTel] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [recharge, setRecharge] = useState("");
  // const [accessToken, setAccessToken] = useState(null);
  const [convertedAmount, setConvertedAmount] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);

  const consentToken = Cookies.get("consentBangTopupToken");


   useEffect(() => {
      const convertedAmountVal = amount / conversion_rate;
      setConvertedAmount(convertedAmountVal.toFixed(2));
   }, [amount, conversion_rate]); 

  const closeModal = () => {
    setToggleModal(false);
    setAmount("");
    setOperator("")
    setTel(""); 
  };


  const handleAmountChange = (e) => {
    let val = e.target.value;
    // let cleanValue = val.replace(/[^0-9]/g, '');
    const regex = /^[0-9.]+$/;
    if (val.match(regex) || val === "") {
      setAmount(val);
      setErrorAmount(false);
    }
  };

  const handleOperatorChange = (e) => {
    setOperator(e.target.value);
    setErrorOperator(false);
  };

  const handleTelChange = (event) => {
    let val = event.target.value;
    const numericValue = val.replace(/[^0-9]/g, "");
    setTel(numericValue);
    setErrorTel(false);
  };

  const handleRadioChange = (e) => {
    setPlanType(e.target.value);
  };

  const handlePhonCodeChange = (e) => {
    setPhoneCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (tel === "" || amount === "" || operator === "") {
      if (tel === "") {
        setErrorTel(true);
      }
      if (amount === "") setErrorAmount(true);
      if (operator === "") setErrorOperator(true);
    } else if(tel.length < 10 ) {
      toast.error("Please enter valid mobile number");
    }  else {
     let telVal = phoneCode + "" + tel;
     let body = {
      msisdn: telVal,
      amount: Number(amount),
      operator: operator,
      con_type: planType,
      recharge_type: "Topup",
      consent_token: consentToken,
      converted_amount: Number(convertedAmount)
     }
      try {
        setLoadingBtn(true);
        const response = await axiosInstance.post("/api/BangTopup/mobileRechargeTopup", body, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        })
        const  {  status } = response.data;
        if(status === 200) {
          setLoadingBtn(false);
          const  { data } = response.data;
          toast.info(data?.message);
          if(data?.redirect_url) {
            window.location.href = data?.redirect_url;
          }
          setAmount("");
          setOperator("")
          setTel(""); 
          // setRecharge(data);
          // setToggleModal(true);
        }
      } catch (error) {
        const { message } = error.response.data;
        toast.error(message);
        setAmount("");
        setOperator("")
        setTel(""); 
        setLoadingBtn(false);
      }
  };
  };
  

  return (
    <section className="container mt-6">
      <div className="lg:w-[500px] lg:mx-auto">
        {/* <h1 className="heading-one !text-black !mb-4">Recharge</h1> */}

        <form onSubmit={handleSubmit}>
          <div className={`mb-4`}>
            <label className="font-mulish text-sm font-medium text-secondary-grey">Mobile Number</label>
            {errortel ? (
              <span className="text-sm text-red-800 block">
                Please Fill Out Phone Field
              </span>
            ) : (
              <></>
            )}
            <div>
            </div>
            <input
              type="tel"
              placeholder="Phone"
              name="phoneNumber"
              value={tel}
              onChange={handleTelChange}
              className={`w-full outline-none border-[1px] pl-2 text-secondary-grey border-secondary-grey h-12 rounded-md`}
              maxLength={11}
              minLength={10}
              // required
            />
          </div>
          <div className={`mb-4`}>
            <div className="flex gap-4 items-center">
              <div className="flex gap-2 items-center">
                <input
                  type="radio"
                  name="planType"
                  value="prepaid"
                  checked={planType === "prepaid"}
                  onChange={handleRadioChange}
                  className="customradio accent-primary-red"
                />
                <p className="text-xsmall">Prepaid</p>
              </div>
              <div className="flex gap-2 items-center">
                <input
                  type="radio"
                  name="planType"
                  value="postpaid"
                  checked={planType === "postpaid"}
                  onChange={handleRadioChange}
                  className="customradio accent-primary-red"
                />
                <p className="text-xsmall">Postpaid</p>
              </div>
            </div>
          </div>
          <div className={`mb-4`}>
            <label className="font-mulish text-sm font-medium text-secondary-grey">Amount</label>
            {errorAmount ? (
              <span className="text-sm text-red-800 block">
                Please Fill Out Amount Field
              </span>
            ) : (
              <></>
            )}
            <input
              type="text"
              placeholder="Enter Amount"
              name="amount"
              value={amount}
              onChange={handleAmountChange}
              className={`w-full outline-none border-[1px] pl-2 text-secondary-grey border-secondary-grey h-12 rounded-md`}
              // required
            />
            <p className="text-base text-black">Amount will charge in RM &#8776; : <span className="text-red-800">{ convertedAmount }</span></p>
          </div>
          
          <div className={`mb-4`}>
            <label
              htmlFor="operator"
              className="font-mulish text-sm font-medium text-secondary-grey"
            >
              Operator
            </label>
            {errorOperator ? (
              <span className="text-sm text-red-800 block">
                * Please Select An Operator
              </span>
            ) : (
              <></>
            )}
            <select
              id="operator"
              name="operator"
              value={operator}
              onChange={handleOperatorChange}
              className={`w-full outline-none border-[1px] pl-2 text-secondary-grey border-secondary-grey h-12 rounded-md`}
              // required
            >
              <option value="">Choose an operator</option>
              <option value="GP">GrameenPhene - GP</option>
              <option value="BL">Banglalink - BL</option>
              <option value="RB">Robi - RB</option>
              <option value="AT">Airtel - AT</option>
              <option value="TT">TeleTalk - TT</option>
              <option value="GP ST">Skitto - GP ST</option>
              {/* Add other options if needed */}
            </select>
          </div>

          {/* Outlet field */}
          {/* TODO: Outlet field */}
          
          <div className="">
            <button type="submit"
             className="
            text-white font-poppins font-medium text-base mb-3 flex justify-center items-center bg-primary-red w-full px-4 py-18 h-14 rounded-lg"
            
            >
               { loadingBtn ? (
                             <TailSpin
                             height="40"
                             width="40"
                             color="#000000"
                             ariaLabel="tail-spin-loading"
                             radius="1"
                             wrapperStyle={{}}
                             wrapperClass=""
                             visible={true}
                           />
                           ) : (
                            <>Submit</>
                           )}
            </button>
          
          </div>
        </form>
      </div>
      <ToastContainer />
      {toggleModal && (
        <RechargeMsgModal recharge={recharge} close={closeModal} />
      )}
    </section>
  );
};

export default Recharge;
