import Cookies from "js-cookie";

const appData = Cookies?.get("appData") ? JSON.parse(Cookies?.get("appData")) : null;

const initialState = {
  loginData: appData,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
     case "LOGIN_DATA":
      return {
        ...state,
        loginData: action.payload
      }  
    default: {
      return state;
    }
  }
};

export default Reducer;
