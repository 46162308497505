import React, { useEffect, useState } from "react";
import {  axiosInstance } from "../utils";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { AuthModal } from "../components";
const Auth = () => {
  const [showAuthModal] = useState(true);
  const [isErrMsg, setIsErrMsg] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const navigate = useNavigate(); 

  useEffect(() => {
    getAuthParams();
  }, []);

  const getAuthParams = async () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = new URLSearchParams(url.search);
    const walletUserSessToken = searchParams.get("WalletUserSessToken");
    const appName = searchParams.get("appName");
    const userType = searchParams.get("userType");
    
    sessionStorage?.setItem('appName', appName);
    const consentTokenVal =  appName === 'BangTopup' ? Cookies.get("consentBangTopupToken") : Cookies.get("consentRichtechTopupToken");
    
    Cookies.set("walletUserSessToken", walletUserSessToken);
    try{
      debugger;
      const response = await axiosInstance.get(`/api/enquireUserConsent?WalletUserSessToken=${walletUserSessToken}&appType=${appName}&consentToken=${consentTokenVal ?? ''}`);
      setIsErrMsg(false);
      setErrMsg(false);
      const { data, status } = response?.data;
      if(status === 200) {
      Cookies?.set('appData', JSON.stringify(data));
      const { consentStatus, redirectUrl, consentToken } = data;
      if(appName === 'BangTopup') { 
        Cookies.set('consentBangTopupToken', consentToken)
     }
      if(appName === 'RichtechTopup')  { Cookies.set('consentRichtechTopupToken', consentToken) }
      if(consentStatus !== "APPR") {
        window.location.href = redirectUrl;
      } else {
        navigate("/home");
      }
      setIsErrMsg(false);
      setErrMsg(false);
    }
   }catch(error) {
    setIsErrMsg(true);
    const { ErrMsg } = error.response.data.data;
    setErrMsg(ErrMsg);
   } 
  }

  return (
    <div className="container">
    {showAuthModal && <AuthModal isErrMsg={isErrMsg} errMsg={errMsg} />}
    </div>
  );
};

export default Auth;
