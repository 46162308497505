import React, { useState } from "react";
import { Button, EnquiryMsgModal } from "../components";
import { axiosInstance } from "../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";


const RechargeEnquiry = () => {
  const userData = useSelector((state) => state?.Reducer?.loginData);
  const { accessToken } = userData;
  const appName = sessionStorage?.getItem('appName');
  const [transId, setTransId] = useState("");
  const [toggleModal, setToggleModal] = useState(false);
  const [rechargeEnquiryData, setRechargeEnquiryData] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  
  const closeModal = () => {
    setToggleModal(false);
    setTransId("");
    document.body.style.overflow = "auto";
  }

  const handleTransId = (e) => {
    setTransId(e.target.value);
  }

  const handleRechargeEnquiry = async (e) => {
     e.preventDefault();
     let url;
     if(appName === 'BangTopup') {
       url = `/api/BangTopup/mobileRechargeTopupEnquiry/${transId}`
     } else {
      url = `/api/RichtechTopup/checkTransactionStatus/${transId}`
     }
     if(transId === "") {
       toast.error("Please enter Transaction Id");
     } else {
     try{
      setLoadingBtn(true);
      const response = await axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const { status } = response.data;
      if(status === 200) {
        setLoadingBtn(false);
        const { data } = response.data;
        setToggleModal(true);
        setRechargeEnquiryData(data);
      }
     } catch(error) {
       const { message } = error.response.data;
       toast.error(message);
       setLoadingBtn(false);
     }
    }
  }

  return (
    <section className="container mt-6">
      <h1 className="heading-one !text-black !mb-4">Recharge Enquiry</h1>

      <form>
        <div className="flex flex-col gap-3 items-center">
          <div className="mb-4 flex flex-col w-full">
            <label className="font-mulish text-sm font-medium text-secondary-grey">Transaction Id</label>
            <input
              type="text"
              placeholder="******-****-*****-****"
              name="transid"
              value={transId}
              onChange={handleTransId}
              className={`w-full h-12 border-[1px] border-primary-black rounded-lg outline-none p-4 text-xsmall text-secondary-grey placeholder:text-xsmall placeholder:text-secondary-grey`}
            />
          </div>
        </div>
        <button  
        onClick={handleRechargeEnquiry}
        className=
        "button mb-3 text-white flex justify-center items-center bg-primary-red w-full px-4 py-18 h-14 rounded-lg" 
        >
           { loadingBtn ? (
                             <TailSpin
                             height="25"
                             width="25"
                             color="#ffffff"
                             ariaLabel="tail-spin-loading"
                             radius="1"
                             wrapperStyle={{}}
                             wrapperClass=""
                             visible={true}
                           />
                           ) : (
                            <>Submit</>
                           )}
        </button>
      </form>
      <ToastContainer />
      {toggleModal && <EnquiryMsgModal close={closeModal} rechargeData={rechargeEnquiryData} />}
    </section>
  );
};

export default RechargeEnquiry;
