import React from "react";
import ReactDOM from "react-dom";
import { TailSpin } from "react-loader-spinner";
import { BiSolidErrorCircle } from "react-icons/bi";


const AuthModal = ({isErrMsg, errMsg}) => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center overflow-hidden rounded-md">
      <div className="fixed inset-0 z-10 overflow-hidden bg-black opacity-50"></div>
      <div className="terms-modal fixed top-0 left-0 z-20 flex h-full w-full items-center justify-center">
        <div className="relative max-h-[90vh] max-w-[90vw] rounded-lg bg-black p-6 sm:max-w-[80vw]">
          <div className="flex justify-around items-center gap-2 p-5">
            { !isErrMsg && (
            <TailSpin
              height="20"
              width="20"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            )}
            <div className="text-md !text-white">
              { errMsg ? (
              <div className="flex gap-3 items-center">
               <BiSolidErrorCircle className="" />
               <p>{errMsg}</p>
              </div>
              )
              // ) : errMsg?.length > 0 ? (
              //  <div className="flex gap-3 items-center">
              //   { errMsg?.map((err, index) =>  <p key={index}>{err}</p>) }
              //  </div>
              // )
               : (
                <p>
                  Please wait, this will take a while
                </p>
              ) }
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default AuthModal;
