import React from 'react'

const Card = ({data}) => {
  return (
    <div className="w-full  p-4 rounded-md shadow-xl">
        <div className='flex flex-col gap-16 '>
        <div className='grid grid-cols-2 gap-4'>
        <div className='flex flex-col gap-1'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>{data?.type === 'Topup' ? 'Type' : 'Package Name'}</h6>
                <h6 className='text-base font-mulish font-medium text-primary-lightWhite'>{data?.type}</h6>
            </div>
            <div className='flex flex-col gap-1 items-end'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>Transaction ID</h6>
                <h6 className='text-base  font-mulish font-medium text-primary-lightWhite'>{data?.transId}</h6>
            </div>
            <div className='flex flex-col gap-1 items-start'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>Amount</h6>
                <h6 className='text-base font-mulish font-medium text-primary-lightWhite'>{data?.amount} RM</h6>
            </div>
            <div className='flex flex-col gap-1 items-end'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>Status</h6>
                <h6 className={`text-base font-mulish font-medium ${data?.status === 'Failed' ? 'text-red-700' : 'text-[#27BE0E]'} `}>{data?.status}</h6>
            </div>
            <div className='flex flex-col gap-1 items-start col-span-2'>
                <h6 className='text-base font-mulish font-normal text-secondary-grey'>Time</h6>
                <h6 className='text-base font-mulish font-medium text-primary-lightWhite'>{data?.created_at}</h6>
            </div>
        </div>
        {/* <div className='grid grid-cols-2 gap-4'>
        <div className='flex flex-col gap-1'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Package Name</h6>
                <h6 className='text-xs font-mulish font-normal text-primary-lightWhite'>Net Package</h6>
            </div>
            <div className='flex flex-col gap-1 items-end'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Package Name</h6>
                <h6 className='text-xs  font-mulish font-normal text-primary-lightWhite'>Net Package</h6>
            </div>
            <div className='flex flex-col gap-1 items-start'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Package Name</h6>
                <h6 className='text-xs font-mulish font-normal text-primary-lightWhite'>Net Package</h6>
            </div>
            <div className='flex flex-col gap-1 items-start'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Package Name</h6>
                <h6 className='text-xs font-mulish font-normal text-primary-lightWhite'>Net Package</h6>
            </div>
            <div className='flex flex-col gap-1 items-start'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Package Name</h6>
                <h6 className='text-xs font-mulish font-normal text-primary-lightWhite'>Net Package</h6>
            </div>
        </div> */}
        </div>
       {/* <div className='flex flex-col gap-6'>
       <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Package Name</h6>
                <h6 className='text-xs font-mulish font-normal text-primary-lightWhite'>Net Package</h6>
            </div>
            <div className='flex flex-col gap-1'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Transaction ID</h6>
                <h6 className='text-sm font-mulish font-medium text-primary-lightWhite'>1145677</h6>
            </div>
        </div>
        <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Amount</h6>
                <h6 className='text-xs font-mulish font-normal text-primary-lightWhite'>24 RM</h6>
            </div>
            <div className='flex flex-col gap-1'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Status</h6>
                <h6 className='text-xs font-mulish font-normal text-primary-lightWhite'>Successful</h6>
            </div>
        </div>
        <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
                <h6 className='text-xs font-mulish font-normal text-secondary-grey'>Time</h6>
                <h6 className='text-xs font-mulish font-normal text-primary-lightWhite'>02:38 pm</h6>
            </div>
        </div>
       </div> */}
    </div>
  )
}

export default Card