import React, {  useState } from "react";
// import { TopupModal } from "../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../utils";
import OfferSubscribeModal from "./OfferSubscribeModal";
import { useSelector } from "react-redux";


const Product = ({proData}) => {
  const userData = useSelector((state) => state?.Reducer?.loginData);
  const { accessToken } = userData;
  const [loadingBtn, setLoadingBtn] = useState(false);
 
  const [openModal, setOpenModal] = useState(false);
 

 
  const openSubscribeModal = () => {
    setOpenModal(true);
    document.body.style.overflow = "hidden";
  }
 

  const cancelRequest = () => {
    setOpenModal(false);
    document.body.style.overflow = "auto";
  };

  const applyOffer = async (body) => {
    try{
      setLoadingBtn(true);
     const response = await axiosInstance.post("/api/RichtechTopup/requestTopup", body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
     })
     
     const { status } = response.data;
     if(status === 200) {
      const { data } = response.data;
      setLoadingBtn(false);
      toast.info(data?.message);
          if(data?.redirect_url) {
            window.location.href = data?.redirect_url;
          }
      cancelRequest();
      // setResponseModal(true);
      // setOfferResponse(data);
     }
    }catch(error){
      toast.error(error?.response?.data?.data?.message);
      cancelRequest();
      setLoadingBtn(false);
    }
  }
  

  return (
    <section className="mt-9 container">
        <div className="grid grid-cols-1 gap-6 my-7 items-center">
        
            
              <div
                className="w-full min-h-[200px] rounded-lg bg-white border border-gray-200 shadow-md p-6"
                
              >
                <div className="grid grid-cols-2 space-y-2">
                <h6 className="font-poppins flex items-end text-base text-primary-black font-normal">
                        Category:
                </h6>
                <p className="font-poppins text-base text-primary-red font-normal">
                        {proData?.Category}
                </p>
                <h6 className="font-poppins text-base text-primary-black font-normal">
                        Name:
                      </h6>
                      <p className="font-poppins text-base text-primary-red font-normal">
                        {proData?.Name}
                      </p>
                      <h6 className="font-poppins text-base text-primary-black font-normal">
                        Price:
                      </h6>
                      <p className="font-poppins text-base text-primary-red font-normal">
                        {proData?.Cost} RM
                      </p>
                      <h6 className="font-poppins text-base text-primary-black font-normal">
                        status:
                      </h6>
                      <p className="font-poppins text-base text-green-700 font-normal">
                        {proData?.Status}
                      </p>
                      <h6 className="font-poppins text-base text-primary-black font-normal">
                        Tax:
                      </h6>
                      <p className="font-poppins text-base text-primary-red font-normal">
                        {proData?.Tax} RM
                      </p>
                     {
                       (proData?.Description !== '' || proData?.Description !== null || proData?.Description !== undefined ) && (
                        <>
                         <h6 className="font-poppins text-base text-primary-black font-normal flex justify-start">
                        Description:
                      </h6>
                      <p
                        className={`font-poppins text-base text-primary-red font-normal text-clip w-full  items-center break-all`}
                      >
                        {proData?.Description}
                      </p>
                        </>
                       )
                     }
                           
                      
                </div>
                        <div className="pt-5 flex justify-end">
                        <button onClick={openSubscribeModal} className="flex justify-center items-center px-4 py-2 bg-primary-red text-white rounded-xl">  
                            Subscribe
                        </button>
                        </div>

                


              </div>
           
           { openModal && <OfferSubscribeModal close={cancelRequest} offerSubData={proData} applyOffer={applyOffer} loadingBtn={loadingBtn}  /> }
        </div>
      <ToastContainer />
    </section>
  );
};

export default Product;
