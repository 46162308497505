import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import topup from "../assets/svg/topup.svg";
import history from "../assets/svg/history.svg";
import mobile_package from "../assets/svg/package.svg";
import enquiry from "../assets/svg/enquiry.svg";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const Home = () => {
  const userData = useSelector((state) => state?.Reducer?.loginData);
  const { fullName } = userData;
  const appNameSession = sessionStorage?.getItem('appName');
  const [appName, setappName] = useState(appNameSession);
  const walletUserSessToken = Cookies.get("walletUserSessToken");
  
  return (
    <div className="container mt-6">
      <div className="lg:mx-auto lg:w-[500px]">
        <h3 className="my-7 text-primary-lightWhite font-mulish font-semibold text-base">Welcome back {fullName}!</h3>
        <div className="grid grid-cols-2 gap-6">
          { appName === 'BangTopup' && (
          <div className="w-full h-24 justify-self-center bg-primary-white rounded shadow-md flex items-center justify-center flex-col gap-2 ">
            <Link
              to={"/recharge"}
              className="flex justify-between flex-col gap-2 items-center"
            >
              <ReactSVG src={topup} />
              <p className="">Recharge</p>
            </Link>
          </div>
          ) }
          <div className="w-full h-24 justify-self-center bg-primary-white rounded shadow-md flex flex-col gap-2 justify-center items-center ">
            <Link
              to={"/recharge-offers"}
              className="flex justify-between flex-col gap-2 items-center"
            >
              <ReactSVG src={mobile_package} />
              <p className="text-xsmall">Package Offers</p>
            </Link>
          </div>

          <div className="w-full h-24 justify-self-center bg-primary-white rounded shadow-md flex flex-col gap-2 justify-center items-center ">
            <Link
              to={"/recharge-enquiry"}
              className="flex justify-between flex-col gap-2 items-center"
            >
              <ReactSVG src={enquiry} />
              <p className="text-xsmall">Recharge Enquiry</p>
            </Link>
          </div>

          <div className="w-full h-24 justify-self-center bg-primary-white rounded shadow-md flex flex-col gap-2 justify-center items-center ">
            <Link
              to={"/history"}
              className="flex justify-between flex-col gap-2 items-center"
            >
              <ReactSVG src={history} />
              <p className="text-xsmall">History</p>
            </Link>
          </div>
      </div>
      </div>
      <div className="break-all">
        { walletUserSessToken } - "wallet"
      </div>
      <div>
        { appName ?? 'RichtechTopup' }
      </div>
    </div>
  );
};

export default Home;
