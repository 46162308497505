import React, { useState } from 'react';

function Calculator() {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');

  const handleButtonClick = (value) => {
    if (value === '=') {
      try {
        const evaluationResult = eval(input);
        setInput(evaluationResult.toString());
        setResult(evaluationResult);
      } catch (error) {
        setInput('Error');
        setResult('Error');
      }
    } else if (value === 'C') {
      setInput('');
      setResult('');
    } else {
      setInput(input + value);
    }
  };

  return (
    <div className="max-w-sm mx-auto p-4 bg-gray-100 rounded-lg mt-6">
      <input
        type="text"
        className="w-full h-10 p-2 text-xl mb-4 bg-white rounded"
        value={input}
        readOnly
      />
      <div className="grid grid-cols-4 gap-2">
        {['C','1', '2', '3', '+', '4', '5', '6', '-', '7', '8', '9', '*', '0', '=', '/'].map((item, index) => (
          <button
            key={index} 
            onClick={() => handleButtonClick(item)}
            className="p-2 text-lg bg-primary-red text-white rounded hover:bg-red-700"
          >
            {item}
          </button>
        ))}
      </div>
      
    </div>
  );
}

export default Calculator;
