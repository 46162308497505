import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "../Layout";
import {  Recharge, RechargeEnquiry, Offers, Home, Calculator, Auth, History } from "../Pages";

const AppRoute = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" exact element={<Auth />} />
          <Route path="/recharge" element={<Recharge />} />
          <Route path="/recharge-enquiry" element={<RechargeEnquiry />} />
          <Route path="/recharge-offers" element={<Offers />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/home" element={<Home />} />
          <Route path="/history" element={<History />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AppRoute;
